@tailwind base;
@tailwind components;
@tailwind utilities;

.tab-link {
    transition: transform 0.2s ease;
}

.tab-link:hover {
    transform: scale(1.1);
}

.tab-link:active {
    transform: scale(0.95);
}

.transition-opacity {
    transition: opacity 0.3s ease;
}

/* New styles for better touch interaction */
@media (hover: none) {
  button, 
  [role="button"], 
  .clickable {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  button:active, 
  [role="button"]:active, 
  .clickable:active {
    opacity: 0.7;
  }
}

/* Remove duplicate tap-effect styles as they're in tapEffect.css */